import pdf from '@/mixins/pdf'
import formatter from '@/mixins/formatter'
import { PageSizes } from 'pdf-lib'

export default {

  mixins: [pdf, formatter],

  data: () => ({
    pdfVoucher: {
      logo: null,
      date: null,
      document_number: null,
      amount: 0.00,
      actual_name: null,
      budget_name: null,
      item_name: null,
      particulars: null,
      transaction_type: null,
      bp_tin: null,
      mode_of_payment: null,
      report_period: null,
      deposit_bank: null,
      deposit_account_number: null,
      deposit_account_name: null,
      invoice_amount: 0.00,
      taxable_amount: 0.00,
      withholding_tax: 0.00,
      approvals: [],
      voucher_number: null,
      voucher_type: null,
      payee: null,
      customer: null,
      customer_code: null,
      amount_due: 0.00,
      amount_due_in_words: null,
      approver: null
    },
    rePrinted: false,
    layouts: {
      outsideBorderOnly: {
        hLineWidth: function (i, node) {
          return (i === 0 || i === node.table.body.length) ? 1 : 0 // Outside border width
        },
        vLineWidth: function (i, node) {
          return (i === 0 || i === node.table.widths.length) ? 1 : 0 // Outside border width
        },
        hLineColor: function () {
          return 'black' // Outside border color
        },
        vLineColor: function () {
          return 'black' // Outside border color
        }
      },
      recievedBy: {
        hLineWidth: function (i, node) {
          return (i === 0 || i === node.table.body.length) ? 0.1 : 0 // Outside border width
        },
        vLineWidth: () => 0,
        paddingLeft: () => 0,
        paddingRight: () => 0.5,
        hLineColor: () => 'black',
        vLineColor: () => 'black'
      }
    }
  }),

  methods: {

    PDFLine (color = 'black', width = 25) {
      return {
        canvas: [{
          type: 'line',
          x1: 0,
          y1: 1,
          x2: (PageSizes.Letter[0] - width),
          y2: 1,
          lineHeight: 0.5,
          lineWidth: 0.5,
          color: color
        }]
      }
    },

    PDFRow (firstColumn = [], secondColumn = [], firstWidth = '65%', secondWidth = '35%') {
      return {
        width: '*',
        margin: [0, 0],
        columns: [
          {
            width: firstWidth,
            stack: firstColumn
          },
          {
            width: secondWidth,
            stack: secondColumn
          }
        ]
      }
    },

    previewVoucher (
      pdfVoucher = {
        logo: null,
        date: null,
        document_number: null,
        amount: 0.00,
        actual_name: null,
        budget_name: null,
        item_name: null,
        particulars: null,
        transaction_type: null,
        bp_tin: null,
        mode_of_payment: null,
        report_period: null,
        deposit_bank: null,
        deposit_account_number: null,
        deposit_account_name: null,
        invoice_amount: 0.00,
        taxable_amount: 0.00,
        withholding_tax: 0.00,
        approvals: [],
        voucher_number: null,
        voucher_type: null,
        payee: null,
        customer: null,
        customer_code: null,
        amount_due: 0.00,
        amount_due_in_words: null,
        approver: null
      },
      rePrinted = false
    ) {
      this.pdfVoucher = pdfVoucher
      this.rePrinted = rePrinted
      const rePrintedBy = this.$store.getters['auth/user'].name || ' '

      const footer = rePrinted ? {
        stack: [
          'This is an electronically generated form. No handwritten signature is needed on prepared and approved by.',
          `Reprinted by ${rePrintedBy} on ${this.dateTimeShortFormatter(this.pdfVoucher.date)?.toUpperCase()}.`
        ]
      } : {
        text: [
          'This is an electronically generated form. ',
          'No handwritten signature is needed on prepared and approved by.'
        ]
      }

      return this.pdfMake({
        header: this.PDFVoucherHeader(),
        content: [
          this.PDFRow(
            [
              this.PDFVoucherDate()
            ],
            [
              this.PDFVoucherNumbers()
            ],
            '57%',
            '43%'
          ),
          this.PDFVoucherPayeeDetails(),
          this.PDFVoucherAmountInWords(),
          this.PDFLine(),
          this.PDFVoucherAmounts(),
          this.PDFLine(),
          this.PDFVoucherCenters(),
          this.PDFLine(),
          this.PDFVoucherBankDetails(),
          this.PDFLine(),
          this.PDFVoucherSignatories(),
          // this.PDFLine(),
          this.PDFVoucherReceivedBy()
        ],
        footer: {
          ...footer,
          italics: true,
          fontSize: 9,
          alignment: 'center',
          margin: [10, -10]
        }
        // watermark: rePrinted ? {
        //   text: 'REPRINTED',
        //   // color: 'red',
        //   opacity: 0.3,
        //   bold: true
        // } : null
      },
      {
        pageSize: {
          height: PageSizes.Letter[1] / 2,
          width: PageSizes.Letter[0]
        },
        pageMargins: [15, 59, 10, 20],
        pageOrientation: 'landscape',
        fontSize: 10
      },
      {
        title: `${this.pdfVoucher.voucher_type.replace('-', ' ')} Voucher`,
        author: 'Integrated Finance Accounting System',
        creator: 'Integrated Finance Accounting System',
        subject: ''
      })
    },

    PDFVoucherHeader () {
      return [{
        margin: [15, 21, 10, 0],
        columns: [{
          layout: 'noBorders',
          table: {
            widths: ['28%', '44%', '28%'],
            body: [
              [
                {
                  text: '',
                  margin: [0, 5],
                  alignment: 'center',
                  bold: true,
                  fontSize: 12,
                  rowSpan: 2
                },
                {
                  text: process.env?.VUE_APP_VOUCHER_COMPANY_NAME || '',
                  alignment: 'center',
                  margin: [0, 5, 0, 0],
                  bold: true
                },
                {
                  text: this.pdfVoucher.voucher_number,
                  alignment: 'center',
                  bold: true,
                  fontSize: 16
                }
              ],
              [
                '',
                {
                  text: process.env?.VUE_APP_VOUCHER_COMPANY_ADDRESS || '',
                  alignment: 'center',
                  fontSize: 8
                },
                {
                  text: `${this.pdfVoucher.voucher_type.replace('-', ' ')} Voucher`.toUpperCase(),
                  alignment: 'center',
                  bold: true,
                  fontSize: 8
                }
              ]
            ]
          }
        }]
      }]
    },

    PDFVoucherDate () {
      return {
        margin: [0, 0, 0, 0],
        layout: 'noBorders',
        table: {
          headerRows: 1,
          widths: ['14%', '86%'],
          body: [
            [
              {
                text: 'Date:',
                bold: false
              },
              {
                text: this.dateTimeShortFormatter(this.pdfVoucher.date)?.toUpperCase(),
                alignment: 'left',
                bold: true
              }
            ]
          ]
        }
      }
    },

    PDFVoucherNumbers () {
      return {
        margin: [0, 0, 0, 0],
        layout: this.layouts.recievedBy,
        table: {
          headerRows: 1,
          widths: ['45%', '55%'],
          body: this.pdfVoucher.voucher_type?.toLowerCase() === 'check' ? [
            [
              {
                text: 'Check No. and Date:',
                border: [false, false, false, false],
                bold: false
              },
              {
                text: '',
                alignment: 'right',
                border: [false, false, false, true],
                bold: true
              }
            ]
          ] : [
            [
              {
                text: '',
                alignment: 'right',
                border: [false, false, false, false],
                bold: true
              },
              {
                text: '',
                alignment: 'right',
                border: [false, false, false, false],
                bold: true
              }
            ]
          ]
        }
      }
    },

    PDFVoucherPayeeDetails () {
      return {
        margin: [0, 0, 0, 0],
        layout: 'noBorders',
        table: {
          headerRows: 1,
          widths: ['8%', '92%'],
          body: [
            [
              {
                text: 'Payee:',
                bold: false
              },
              {
                text: this.pdfVoucher.payee || ''
              }
            ],
            [
              {
                text: 'TIN:',
                bold: false
              },
              {
                text: this.pdfVoucher.bp_tin ? this.pdfVoucher.bp_tin.substring(0, 17) : ' ',
                alignment: 'left'
              }
            ]
          ]
        }
      }
    },

    PDFVoucherAmountInWords () {
      return {
        margin: [0, 0, 0, 0],
        layout: 'noBorders',
        table: {
          headerRows: 1,
          widths: ['8%', '92%'],
          heights: row => {
            if (row === 0) {
              return 23
            }
          },
          body: [
            [
              {
                text: 'Amount:',
                bold: false
              },
              {
                text: `${this.pdfVoucher.amount_due_in_words} Only`,
                alignment: 'left',
                lineHeight: 1.3
              }
            ]
          ]
        }
      }
    },

    PDFVoucherAmounts () {
      return {
        margin: [0, 2, 0, 1],
        layout: 'noBorders',
        table: {
          headerRows: 1,
          widths: ['25%', '25%', '25%', '25%'],
          body: [
            [
              {
                text: 'Invoice Amount:',
                margin: [0, 0, 0, 1],
                bold: false
              },
              {
                text: 'Taxable Amount:',
                margin: [0, 0, 0, 1],
                bold: false
              },
              {
                text: 'W/Tax:',
                margin: [0, 0, 0, 1],
                bold: false
              },
              {
                text: 'Amount Due:',
                margin: [0, 0, 0, 1],
                bold: false
              }
            ],
            [
              {
                text: this.numberFormatter(this.pdfVoucher.invoice_amount) || ' ',
                alignment: 'left',
                fontSize: 12
              },
              {
                text: this.numberFormatter(this.pdfVoucher.taxable_amount) || ' ',
                alignment: 'left',
                fontSize: 12
              },
              {
                text: this.numberFormatter(this.pdfVoucher.withholding_tax) || ' ',
                alignment: 'left',
                fontSize: 12
              },
              {
                text: this.numberFormatter(this.pdfVoucher.amount_due) || ' ',
                alignment: 'left',
                fontSize: 12
              }
            ]
          ]
        }
      }
    },

    PDFVoucherCenters () {
      let itemName = this.pdfVoucher.item_name || ' '
      if (itemName.length >= 68) {
        itemName = `${itemName.substring(0, 65)}...`
      }

      let particulars = this.pdfVoucher.particulars ? this.pdfVoucher.particulars.replace(/(\r\n|\n|\r)/gm, '; ') : ' '
      if (this.pdfVoucher.particulars && particulars.length >= 81) {
        particulars = `${particulars.substring(0, 80)}...`
      }

      return {
        margin: [0, 2, 0, 1],
        layout: 'noBorders',
        table: {
          headerRows: 1,
          widths: ['16%', '84%'],
          body: [
            [
              {
                text: 'Budget Center:',
                margin: [0, 0, 0, 1],
                bold: false
              },
              {
                text: this.pdfVoucher.budget_name,
                lineHeight: 1.3
              }
            ],
            [
              {
                text: 'Item Center:',
                margin: [0, 0, 0, 1],
                bold: false
              },
              {
                text: itemName,
                alignment: 'left',
                lineHeight: 1.3
              }
            ],
            [
              {
                text: 'Particulars: ',
                margin: [0, 0, 0, 1],
                bold: false
              },
              {
                text: particulars || ' - - NO PARTICULARS - - ',
                alignment: particulars ? 'left' : 'center',
                lineHeight: 1.3
              }
            ]
          ]
        }
      }
    },

    PDFVoucherBankDetails () {
      const accountName = this.pdfVoucher.deposit_account_name || ' '

      const accountNumber = this.pdfVoucher.deposit_account_number || ' '

      const bank = this.pdfVoucher.deposit_bank || ' '

      return {
        margin: [0, 2, 0, 1],
        layout: 'noBorders',
        table: {
          headerRows: 1,
          widths: ['16%', '84%'],
          body: [
            [
              {
                text: 'Bank:',
                margin: [0, 0, 0, 1],
                bold: false
              },
              {
                text: (
                  bank.length >= 80 ? (
                    `${bank.substring(0, 80)}...`
                  ) : bank
                ),
                alignment: 'left',
                lineHeight: 1.3
              }
            ],
            [
              {
                text: 'Account No.:',
                margin: [0, 0, 0, 1],
                bold: false
              },
              {
                text: (
                  accountNumber.length >= 80 ? (
                    `${accountNumber.substring(0, 80)}...`
                  ) : accountNumber
                ),
                alignment: 'left',
                lineHeight: 1.3
              }
            ],
            [
              {
                text: 'Account Name:',
                margin: [0, 0, 0, 1],
                bold: false
              },
              {
                text: (
                  accountName.length >= 80 ? (
                    `${accountName.substring(0, 80)}...`
                  ) : accountName
                ),
                alignment: 'left',
                lineHeight: 1.3
              }
            ]
          ]
        }
      }
    },

    PDFVoucherSignatories () {
      const blankSignature = { text: ' ' }

      const preparedBy = this.pdfVoucher.approvals.find(
        approver => Number(approver.stage) === 4
      )

      const PreparedBySignature = preparedBy ? this.PDFVoucherSignatory(
        'Prepared by:',
        preparedBy.approver_name || this.$store.getters['auth/user'].name,
        this.dateTimeShortFormatter(preparedBy.status_at ? preparedBy.status_at : this.pdfVoucher.date)?.toUpperCase(),
        false
      ) : blankSignature

      const approvedBy = this.pdfVoucher.approvals.find(
        approver => Number(approver.stage) === 3
      )

      const ApprovedBySignature = approvedBy ? this.PDFVoucherSignatory(
        'Approved by:',
        approvedBy.approver_name || ' ',
        this.dateTimeShortFormatter(approvedBy.status_at)?.toUpperCase(),
        false
      ) : blankSignature

      // const PostedBySignature = this.PDFVoucherSignatory(
      //   'Posted by:',
      //   ' ',
      //   '',
      //   true
      // )

      const ReceivedBySignature = this.PDFVoucherSignatory(
        'Received by:',
        ' ',
        'Signature Over Printed Name',
        true, 1.955
      )

      // const ReceivedBySignature = this.PDFVoucherSignatory(
      //   'Received by:',
      //   ' ',
      //   'Signature Over Name',
      //   true
      // )

      return {
        margin: [0, 2, 0, 0],
        layout: 'noBorders',
        table: {
          widths: ['25%', '25%', '25%', '25%'],
          body: [
            [
              PreparedBySignature,
              ApprovedBySignature,
              // PostedBySignature,
              // ReceivedBySignature
              {
                ...ReceivedBySignature,
                colSpan: 2
              }
            ]
          ]
        }
      }
    },

    PDFVoucherReceivedBy () {
      return {
        margin: [0, 0, 0, 0],
        layout: this.layouts.recievedBy,
        table: {
          widths: ['12%', '38%', '12%', '38%'],
          body: [
            [
              {
                text: 'CA Account:',
                border: [false, false, false, false],
                bold: false
              },
              {
                text: this.pdfVoucher.customer_code && this.pdfVoucher.customer ? `${this.pdfVoucher.customer_code} - ${this.pdfVoucher.customer}` : '',
                border: [false, false, false, false],
                bold: true,
                colSpan: 3
              },
              {
                margin: [5, 0, 0, 0],
                alignment: 'left',
                text: 'Posted by:',
                border: [false, false, false, false],
                bold: false
              },
              {
                text: ' ',
                border: [false, false, false, true],
                bold: false
              }
            ]
          ]
        }
      }
    },

    PDFVoucherSignatory (type, name, dateTime, hasLine = true, isReceiver = 0) {
      return {
        margin: [0, 2, 0, 0],
        border: [false, false, false, false],
        stack: [
          {
            text: type,
            margin: [0, 0, 0, 5],
            bold: false
          },
          {
            text: name,
            margin: [0, 0, 0, 3],
            alignment: 'left',
            bold: true
          },
          hasLine ? {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: -1,
                x2: isReceiver > 0 ? (140 * isReceiver) + 15 : 138,
                y2: -1,
                lineHeight: 1,
                lineWidth: 1,
                color: 'black'
              }
            ]
          } : '',
          {
            text: dateTime,
            alignment: hasLine ? 'center' : 'left',
            fontSize: 9,
            bold: false
          },
          hasLine ? {
            text: 'Date and Time',
            alignment: 'center',
            fontSize: 9,
            bold: false
          } : ''
        ]
      }
    }

  }
}
