<template>
  <b-container
    fluid
  >
    <div class="d-flex justify-content-end mb-0 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-tabs
      v-model="state.currentTabIndex"
      active-nav-item-class="font-weight-bold text-uppercase text-success"
    >
      <b-tab>
        <template #title>
          <i :class="iconState('ri-file-edit', state.currentTabIndex === 0)" /> Pending
        </template>
        <PaymentRequestPending
          ref="prPending"
          :busy="state.busy"
          :current-tab-index="state.currentTabIndex"
          @onProcess="onShowPaymentRequest"
        />
      </b-tab>

      <b-tab>
        <template #title>
          <i :class="iconState('ri-archive', state.currentTabIndex === 1)" /> Histories
        </template>
        <PaymentRequestHistories
          ref="prHistories"
          :busy="state.busy"
          :current-tab-index="state.currentTabIndex"
          @onView="onShowPaymentRequest"
        />
      </b-tab>

    </b-tabs>

    <!-- Modals -->
    <b-modal
      id="modal-payment-request"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      modal-class="fullscreen"
    >
      <ValidationObserver
        ref="formPaymentRequest"
      >
        <b-form
          role="form"
          novalidate
          @submit.prevent
        >
          <b-tabs
            active-nav-item-class="font-weight-bold text-uppercase text-success"
            content-class="scrollable"
          >
            <b-tab class="p-2">
              <template #title>
                <div class="text-md-nowrap">
                  <i class="ri-file-text-line h4" />
                  <b class="is-tab-active">Details</b>
                </div>
              </template>
              <b-row>
                <!-- CENTERS -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <b-row>

                    <b-col
                      cols="12"
                    >
                      <b-row>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Transaction Type"
                            label-for="pr_bp_transaction_type"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_bp_transaction_type"
                              v-model="paymentRequest.bp_transaction_type"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          v-if="paymentRequest.bp_transaction_type === 'With-2307'"
                          cols="12"
                        >
                          <b-form-group
                            label="2307 Period"
                            label-for="pr_report_period"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_report_period"
                              v-model="paymentRequest.report_details"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                      </b-row>

                    </b-col>

                    <b-col
                      cols="12"
                    >

                      <b-row>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Budget Center"
                            label-for="pr_budget_center"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_budget_center"
                              v-model="paymentRequest.budget_name"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Item Description"
                            label-for="pr_item_description"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-textarea
                              id="pr_item_description"
                              v-model="paymentRequest.item_name"
                              rows="2"
                              max-rows="4"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                      </b-row>
                    </b-col>

                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="Particulars"
                        label-for="pr_particulars"
                        label-class="font-weight-bolder"
                        class="mb-2"
                      >
                        <b-textarea
                          id="pr_particulars"
                          v-model="paymentRequest.particulars"
                          rows="7"
                          max-rows="12"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                  </b-row>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-row>
                    <!-- BUSINESS PARTNER -->
                    <b-col
                      cols="12"
                    >
                      <b-row>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Business Partner"
                            label-for="pr_business_partner"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-textarea
                              id="pr_business_partner"
                              v-model="paymentRequest.bp_name"
                              rows="2"
                              max-rows="4"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="TIN"
                            label-for="pr_bp_tin"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_bp_tin"
                              v-model="paymentRequest.bp_tin"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="ATC"
                            label-for="pr_bp_tax_code"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_bp_tax_code"
                              v-model="paymentRequest.bp_tax_description"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Type"
                            label-for="pr_bp_partner_type"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_bp_partner_type"
                              v-model="paymentRequest.bp_partner_type"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Business Tax"
                            label-for="pr_bp_business_tax"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_bp_business_tax"
                              v-model="paymentRequest.bp_business_tax"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Tax Status"
                            label-for="pr_bp_tax_status"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_bp_tax_status"
                              v-model="paymentRequest.bp_tax_status"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                      </b-row>
                    </b-col>

                    <b-col
                      cols="12"
                      class="pb-2"
                    >
                      <b-row>

                        <b-col
                          cols="12"
                        >
                          <b-form-group class="mb-2">
                            <b-form-checkbox
                              id="is_cash_advance"
                              v-model="paymentRequest.is_cash_advance"
                              name="is_cash_advance"
                              unchecked-value="0"
                              value="1"
                              switch
                              disabled
                            >
                              <strong>Is Cash Advance?</strong>
                            </b-form-checkbox>
                          </b-form-group>
                          <b-form-group
                            label="Cash Adv. Account"
                            label-for="pr_cash_advance"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_cash_advance"
                              v-model="paymentRequest.customer_name"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                      </b-row>
                    </b-col>

                  </b-row>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-row>
                    <!-- PAYMENTS -->
                    <b-col
                      cols="12"
                    >
                      <b-row>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Mode Of Payment"
                            label-for="pr_mode_of_payment"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_mode_of_payment"
                              v-model="paymentRequest.mop"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Branch"
                            label-for="pr_branch"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_branch"
                              v-model="paymentRequest.branch"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Amount"
                            label-for="pr_amount"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_amount"
                              v-model="paymentRequest.amount"
                              type="text"
                              class="text-right"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Invoice Amount"
                            label-for="pr_invoice_amount"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_invoice_amount"
                              v-model="paymentRequest.invoice_amount"
                              type="text"
                              class="text-right"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Taxable Amount"
                            label-for="pr_taxable_amount"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_taxable_amount"
                              v-model="paymentRequest.taxable_amount"
                              type="text"
                              class="text-right"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Withholding Tax"
                            label-for="pr_with_holding_tax"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_with_holding_tax"
                              v-model="paymentRequest.withholding_tax"
                              type="text"
                              class="text-right"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Amount Due"
                            label-for="pr_amount_due"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_amount_due"
                              v-model="paymentRequest.amount_due"
                              type="text"
                              class="text-right"
                              disabled
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>

                    <!-- BANK ACCOUNT -->
                    <b-col
                      cols="12"
                    >
                      <b-row>

                        <b-col
                          cols="12"
                        >
                          <b-form-group class="mb-2">
                            <b-form-checkbox
                              id="for_deposit"
                              v-model="paymentRequest.for_deposit"
                              switch
                              value="1"
                              name="for_deposit"
                              unchecked-value="0"
                              disabled
                            >
                              <strong>For Deposit?</strong>
                            </b-form-checkbox>
                          </b-form-group>
                          <b-form-group
                            label="Bank"
                            label-for="pr_deposit_bank"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_deposit_bank"
                              v-model="paymentRequest.deposit_bank"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Account Number"
                            label-for="pr_deposit_account_number"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_deposit_account_number"
                              v-model="paymentRequest.deposit_account_number"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                        >
                          <b-form-group
                            label="Account Name"
                            label-for="pr_deposit_account_name"
                            label-cols-md="4"
                            label-class="font-weight-bolder"
                            class="mb-2"
                          >
                            <b-input
                              id="pr_deposit_account_name"
                              v-model="paymentRequest.deposit_account_name"
                              type="text"
                              disabled
                            />
                          </b-form-group>
                        </b-col>

                      </b-row>
                    </b-col>

                  </b-row>
                </b-col>

              </b-row>
            </b-tab>

            <b-tab class="p-2">
              <template #title>
                <div class="text-md-nowrap">
                  <i class="ri-attachment-line h4" />
                  <b class="is-tab-active">Attachments</b>
                </div>
              </template>
              <Attachment
                :file-path="paymentRequest.attachment_path"
                :file-list="paymentRequest.attachments"
                :show-uploader="false"
              />
            </b-tab>

            <b-tab class="p-2">
              <template #title>
                <div class="text-md-nowrap">
                  <i class="ri-file-edit-line h4" />
                  <b class="is-tab-active">Approvals</b>
                </div>
              </template>
              <b-row>
                <b-col
                  class="mb-3 px-2"
                  cols="12"
                  md="3"
                >
                  <Signatory
                    :approver="requesterSignatory"
                    :payment-request="paymentRequest"
                  />
                </b-col>
                <b-col
                  v-for="(approver, key) in paymentRequest.approvals"
                  :key="key"
                  class="mb-3 px-2"
                  cols="12"
                  md="3"
                >
                  <Signatory
                    :approver="approver"
                    :payment-request="paymentRequest"
                  />
                </b-col>
                <b-col
                  v-if="paymentRequest.status === 'Canceled' && Number(paymentRequest.stage) >= 3"
                  class="mb-3 px-2"
                  cols="12"
                  md="3"
                >
                  <Signatory
                    :approver="cancellationSignatory"
                    :payment-request="paymentRequest"
                  />
                </b-col>
              </b-row>
            </b-tab>

            <b-tab>
              <template #title>
                <div class="text-md-nowrap">
                  <i class="ri-printer-line h4" />
                  <b class="is-tab-active">Printing</b>
                </div>
              </template>

              <b-tabs
                v-model="state.printableTabs"
                active-nav-item-class="font-weight-bold text-uppercase text-success"
                content-class="attachment-container"
              >
                <b-tab
                  title="Voucher"
                  class="attachment-container"
                  lazy
                >
                  <b-overlay
                    rounded
                    opacity="1"
                    :show="pdfDatas.requestForPayment === null"
                    spinner-variant="success"
                  >
                    <iframe
                      v-if="Number(state.printableTabs) === 0"
                      :src="pdfDatas.requestForPayment"
                    />
                  </b-overlay>
                </b-tab>

                <b-tab
                  title="Attachments"
                  class="attachment-container"
                  lazy
                >
                  <div
                    v-if="paymentRequest.attachments.length <= 0"
                  >
                    <div class="d-flex justify-content-center align-items-center flex-column">
                      <b-img
                        :src="getInvalidImageUrl"
                      />
                      <div class="h4 py-3">
                        No Attachments
                      </div>
                    </div>
                  </div>
                  <b-overlay
                    v-else
                    rounded
                    opacity="1"
                    :show="state.pdfRendering"
                    spinner-variant="success"
                  >
                    <div class="d-flex flex-column flex-md-row align-items-md-center align-items-start py-2">
                      <b-form-group
                        label="Attachments Per Page"
                        label-for="paper_size"
                        label-class="font-weight-bolder"
                        class="mb-2 col-12 col-md-3"
                      >
                        <b-input-group>
                          <b-form-select
                            id="paper_size"
                            v-model="state.perPage"
                            :options="lists.perPages"
                            :disabled="state.busy"
                          >
                            <template #first>
                              <b-form-select-option
                                :value="null"
                                disabled
                              >
                                -- select paper size here --
                              </b-form-select-option>
                            </template>
                          </b-form-select>
                          <b-input-group-append>
                            <b-button
                              v-print="printableAttachments"
                              variant="success"
                            >
                              Print Attachments
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </div>

                    <b-row
                      id="printable-attachments"
                      class="px-5"
                    >
                      <b-col
                        v-for="(image, key) in pdfDatas.attachments"
                        :key="(`${key}-pdf`)"
                        :cols="([1, 2].includes(Number(state.perPage)) ? '12' : '6')"
                        :style="((Number(key) + 1) % Number(state.perPage) === 0 ? { pageBreakAfter: 'always' } : {})"
                        :class="(`d-flex flex-column justify-content-center align-items-center mt-5 ${state.perPage === 1 ? 'printing-height-one' : 'printing-height'}`)"
                      >
                        <span class="align-self-start">
                          {{ Number(key) + 1 }}) {{ image.file_original_name }}
                        </span>
                        <b-img
                          :src="image.data_uri"
                          :style="{
                            width: [1, 2].includes(Number(state.perPage)) ? 'calc(100vw / 1.15)' : 'calc(100vw / 2.4)',
                            height: 'fit-content'
                          }"
                        />
                      </b-col>
                      <b-col
                        v-for="(image, key) in paymentRequest.attachments.filter(attachment => attachment.file_extension !== 'pdf')"
                        :key="(`${key}-image`)"
                        :cols="([1, 2].includes(Number(state.perPage)) ? '12' : '6')"
                        :style="((Number(pdfDatas.attachments.length) + (Number(key) + 1)) % Number(state.perPage) === 0 ? { pageBreakAfter: 'always' } : {})"
                        :class="(`d-flex flex-column justify-content-center align-items-center mt-5 ${state.perPage === 1 ? 'printing-height-one' : 'printing-height'}`)"
                      >
                        <span class="align-self-start">
                          {{ Number(pdfDatas.attachments.length) + (Number(key) + 1) }}) {{ image.file_original_name }}
                        </span>
                        <b-img
                          :src="uploadedAttachmentEndpoint(image.file_hashed_name)"
                          :style="{
                            width: [1, 2].includes(Number(state.perPage)) ? 'calc(100vw / 1.15)' : 'calc(100vw / 2.4)',
                            height: 'fit-content'
                          }"
                        />
                      </b-col>
                    </b-row>

                  </b-overlay>
                </b-tab>

                <b-tab
                  v-if="paymentRequest.bp_transaction_type === 'With-2307'"
                  title="BIR Form 2307"
                  class="attachment-container"
                  lazy
                >
                  <b-overlay
                    rounded
                    opacity="1"
                    :show="pdfDatas.birForm === null"
                    spinner-variant="success"
                  >
                    <iframe
                      v-if="Number(state.printableTabs) === 2"
                      :src="pdfDatas.birForm"
                    />
                  </b-overlay>
                </b-tab>

              </b-tabs>
            </b-tab>

          </b-tabs>
        </b-form>
      </ValidationObserver>
      <template #modal-footer="{ cancel }">
        <div class="col-12 d-flex justify-content-between px-0 pb-0 mb-0">
          <div
            v-if="state.currentTabIndex === 0 && state.processing"
          >
            <b-button
              class="mr-1"
              variant="success"
              :disabled="state.busy"
              @click="onPrintPaymentRequest"
            >
              Mark Documents As Printed
            </b-button>
          </div>
          <div />
          <div>
            <b-button
              variant="outline-dark"
              :disabled="state.busy"
              @click="cancel()"
            >
              Close <span class="d-none d-md-inline-block">Window</span>
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import PaymentRequestPending from '@/views/da/payments/document-printing/Pending'
import PaymentRequestHistories from '@/views/da/payments/document-printing/Histories'
import Signatory from '@/components/custom/Signatory'
import Attachment from '@/components/custom/Attachment'
import formatter from '@/mixins/formatter'
import formulas from '@/mixins/formulas'
import misc from '@/mixins/misc'
// pdfDatas
import BIR2307 from '@/mixins/printing/bir-2307'
import Voucher from '@/mixins/printing/voucher'
// import PDFViewer from '@/components/custom/PDFViewer'
import { SSharedAttachment } from '@/services'
import { PageSizes } from 'pdf-lib'

import print from 'vue-print-nb'

export default {

  name: 'DaPaymentRequestsDocumentPrinting',

  middleware: ['auth', 'da'],

  metaInfo: () => ({
    title: 'Payment Request'
  }),

  directives: {
    print
  },

  components: {
    PaymentRequestPending,
    PaymentRequestHistories,
    Signatory,
    Attachment
    // PDFViewer
  },

  mixins: [
    formatter,
    misc,
    formulas,
    BIR2307,
    Voucher
  ],

  data () {
    return {
      printableAttachments: {
        id: 'printable-attachments',
        popTitle: 'Integrated Finance Accounting System'
        // openCallback (vue) {
        //   console.log('openCallback', vue)
        // },
        // closeCallback (vue) {
        //   console.log('closeCallback', vue)
        // }
      },
      companyLogo: null,
      state: {
        perPage: 1,
        busy: false,
        processing: false,
        pdfRendering: false,
        currentTabIndex: this.getCurrentTabIndexByURL(),
        printableTabs: 0
      },
      lists: {
        perPages: [
          { text: '1 per page', value: 1 },
          { text: '2 per page', value: 2 },
          { text: '4 per page', value: 4 }
        ]
      },
      pdfDatas: {
        requestForPayment: null,
        attachments: [],
        birForm: null,
        allForms: null
      },
      paymentRequest: {
        id: null,
        stage: 0,
        bp_code: null,
        bp_name: null,
        bp_tin: null,
        bp_partner_type: null,
        bp_vendor_type: null,
        bp_customer_type: null,
        bp_tax_status: null,
        bp_business_tax: null,
        bp_document_type: null,
        bp_tax_code: null,
        bp_tax_description: null,
        is_cash_advance: 0,
        customer_code: null,
        customer_name: null,
        customer_tin: null,
        amount: 0.00,
        budget_code: null,
        budget_name: null,
        item_code: null,
        item_name: null,
        budget_amount: null,
        particulars: null,
        bp_transaction_type: null,
        mop: null,
        branch: null,
        report_year: null,
        report_period: null,
        report_details: null,
        for_deposit: 1,
        deposit_bank: null,
        deposit_account_number: null,
        deposit_account_name: null,
        invoice_amount: 0.00,
        taxable_amount: 0.00,
        withholding_tax: 0.00,
        amount_due: 0.00,
        approvals: [],
        attachment_path: null,
        attachments: [],
        status: 'Printed',
        remarks: null,
        created_at: null
      },
      requesterSignatory: {
        stage: 0,
        approver_label: 'Requester',
        approver_action: 'Requisition',
        approver_name: null,
        last_seen: null,
        status: 'Requested',
        status_at: null,
        remarks: null
      },
      cancellationSignatory: {
        stage: 0,
        approver_label: 'Disbursement Head',
        approver_action: 'Cancellation of Request',
        approver_name: null,
        last_seen: null,
        status: 'Canceled',
        status_at: null,
        remarks: null
      }
    }
  },

  computed: {
    getInvalidImageUrl () {
      return `${window.location.origin}/img/invalid-image.png`
    }
  },

  mounted () {
    core.index()
  },

  methods: {

    uploadedAttachmentEndpoint (fileName) {
      return `${process.env.VUE_APP_API_BASE_URL}/payment-requests-attachments/${this.paymentRequest.attachment_path}/${fileName}`
    },

    getCurrentTabIndexByURL () {
      switch (this.$route.params.da) {
        case 'histories' : {
          return 1
        }
        default: {
          return 0
        }
      }
    },

    retrieveBranding () {
      SSharedAttachment.retrieveBranding(
        'aljay-solutions.png'
      ).then(({ data }) => data.arrayBuffer()).then(
        bufferedArray => {
          const uint8 = new Uint8Array(bufferedArray)
          const convertToString = Array.from(uint8).map(
            byte => String.fromCharCode(byte)
          ).join('')
          this.companyLogo = `data:image/png;base64,${window.btoa(convertToString)}`
        }
      )
    },

    onShowPaymentRequest (paymentRequest, approval, processing) {
      this.state.printableTabs = 0
      this.state.processing = processing
      this.paymentRequest.id = approval.id
      this.paymentRequest.stage = paymentRequest.stage
      // BUSINESS PARTNER
      this.paymentRequest.bp_code = paymentRequest.bp_code
      this.paymentRequest.bp_name = paymentRequest.bp_name
      this.paymentRequest.bp_tin = paymentRequest.bp_tin
      this.paymentRequest.bp_partner_type = paymentRequest.bp_partner_type
      this.paymentRequest.bp_vendor_type = paymentRequest.bp_vendor_type
      this.paymentRequest.bp_customer_type = paymentRequest.bp_customer_type
      this.paymentRequest.bp_tax_status = paymentRequest.bp_tax_status
      this.paymentRequest.bp_business_tax = paymentRequest.bp_business_tax
      this.paymentRequest.bp_tax_code = paymentRequest.bp_tax_code
      if (paymentRequest.tax_code) {
        this.paymentRequest.bp_tax_description = `${paymentRequest.bp_tax_code} - ${paymentRequest.tax_code.description}`
      }
      this.paymentRequest.bp_document_type = paymentRequest.bp_document_type
      // CASH ADVANCE ACCOUNT
      this.paymentRequest.is_cash_advance = paymentRequest.is_cash_advance
      this.paymentRequest.customer_code = paymentRequest.customer_code
      this.paymentRequest.customer_name = paymentRequest.customer_name
      this.paymentRequest.customer_tin = paymentRequest.customer_tin
      // BANK DETAILS
      this.paymentRequest.for_deposit = paymentRequest.for_deposit
      this.paymentRequest.deposit_bank = paymentRequest.deposit_bank
      this.paymentRequest.deposit_account_number = paymentRequest.deposit_account_number
      this.paymentRequest.deposit_account_name = paymentRequest.deposit_account_name
      // REQUEST DETAILS
      this.paymentRequest.amount = this.numberFormatter(paymentRequest.amount)
      this.paymentRequest.particulars = paymentRequest.particulars
      this.paymentRequest.bp_transaction_type = paymentRequest.bp_transaction_type
      this.paymentRequest.mop = paymentRequest.mop
      this.paymentRequest.branch = paymentRequest.branch
      this.paymentRequest.report_year = paymentRequest.report_year
      this.paymentRequest.report_period = paymentRequest.report_period
      this.paymentRequest.report_details = `${paymentRequest.report_period} - ${paymentRequest.report_year}`
      this.paymentRequest.invoice_amount = this.numberFormatter(paymentRequest.invoice_amount)
      this.paymentRequest.taxable_amount = this.numberFormatter(paymentRequest.taxable_amount)
      this.paymentRequest.withholding_tax = this.numberFormatter(paymentRequest.withholding_tax)
      this.paymentRequest.amount_due = this.numberFormatter(paymentRequest.amount_due)
      this.paymentRequest.approvals = paymentRequest.approvals
      this.paymentRequest.attachment_path = paymentRequest.attachment_path
      this.paymentRequest.attachments = paymentRequest.attachments

      this.paymentRequest.status = paymentRequest.status
      this.paymentRequest.remarks = approval.remarks
      this.paymentRequest.created_at = paymentRequest.created_at

      // REQUESTER
      if (paymentRequest.requestor) {
        this.requesterSignatory.approver_name = `${paymentRequest.requestor.first_name} ${paymentRequest.requestor.last_name}`
        this.requesterSignatory.status_at = paymentRequest.created_at
        this.requesterSignatory.status = 'Requested'
        this.requesterSignatory.remarks = null

        if (paymentRequest.status === 'Canceled') {
          if (paymentRequest.canceled_at && paymentRequest.canceled_by) {
            if (Number(paymentRequest.stage) >= 3) {
              this.cancellationSignatory.approver_name = paymentRequest.canceled_by
              this.cancellationSignatory.status_at = paymentRequest.canceled_at
              this.cancellationSignatory.status = 'Canceled'
              this.cancellationSignatory.remarks = paymentRequest.canceled_remarks
            }
          }
        }
      }

      if (paymentRequest.budget_center) {
        this.paymentRequest.budget_code = paymentRequest.budget_center.budget_code
        this.paymentRequest.budget_name = paymentRequest.budget_center.budget_name
      }

      if (paymentRequest.item_center) {
        this.paymentRequest.budget_amount = paymentRequest.item_center.budget_amount
        if (paymentRequest.item_center.item) {
          this.paymentRequest.item_code = paymentRequest.item_center.item.item_code
          this.paymentRequest.item_name = paymentRequest.item_center.item.item_name
        } else {
          this.paymentRequest.item_code = null
          this.paymentRequest.item_name = null
        }
      }

      this.printVoucher(paymentRequest, approval, !processing).then(() => {
        if (paymentRequest.bp_transaction_type === 'With-2307') {
          this.printBIR2307(paymentRequest)
        }

        if (paymentRequest.attachments.length > 0) {
          this.printAttachments(paymentRequest)
        }
      })

      this.$bvModal.show('modal-payment-request')
    },

    printVoucher (paymentRequest, approval, reRrinted = false) {
      return new Promise(resolve => {
        this.previewVoucher({
          logo: this.companyLogo,
          date: Date.now(),
          document_number: paymentRequest.document_number,
          amount: paymentRequest.amount,
          budget_name: this.paymentRequest.budget_name,
          item_name: this.paymentRequest.item_name,
          particulars: paymentRequest.particulars,
          transaction_type: paymentRequest.bp_transaction_type,
          bp_tin: paymentRequest.bp_tin,
          mode_of_payment: paymentRequest.mop,
          report_period: paymentRequest.report_period,
          deposit_bank: paymentRequest.deposit_bank,
          deposit_account_number: paymentRequest.deposit_account_number,
          deposit_account_name: paymentRequest.deposit_account_name,
          invoice_amount: paymentRequest.invoice_amount,
          taxable_amount: paymentRequest.taxable_amount,
          withholding_tax: paymentRequest.withholding_tax,
          approvals: paymentRequest.approvals,
          voucher_number: paymentRequest.voucher.voucher_number,
          voucher_type: paymentRequest.voucher.voucher_type,
          payee: paymentRequest.voucher.payee,
          customer_code: paymentRequest.customer_code,
          customer: paymentRequest.customer_name,
          amount_due: paymentRequest.voucher.amount,
          amount_due_in_words: paymentRequest.voucher.amount_in_words,
          approver: approval
        }, reRrinted).then(
          onProcessRFP => {
            onProcessRFP.getDataUrl(
              pdfRFP => {
                this.pdfLib({
                  title: `${paymentRequest.voucher.voucher_type.replace('-', ' ')} Voucher`,
                  author: 'Integrated Finance Accounting System',
                  creator: 'Integrated Finance Accounting System',
                  subject: 'Payment Request'
                }).then(
                  async pdfDocument => {
                    await this.pdfLibCopyPages(
                      pdfDocument,
                      pdfRFP,
                      [0],
                      async (rfpPage, _key, pdfDoc) => {
                        const embedRFPPage = await pdfDoc.embedPage(rfpPage)
                        const page = pdfDoc.addPage([PageSizes.Letter[0], PageSizes.Letter[1] / 2])
                        page.drawPage(embedRFPPage, {
                          y: 0,
                          x: 0
                        })
                        pdfDoc.save()
                      }
                    )
                    return pdfDocument
                  }
                ).then(
                  onProcessPrintAll => {
                    onProcessPrintAll.saveAsBase64({ dataUri: true }).then(
                      allForms => {
                        this.pdfDatas.requestForPayment = allForms
                        resolve(allForms)
                      }
                    )
                  }
                )
              }
            )
          }
        )
      })
    },

    printBIR2307 (paymentRequest) {
      return new Promise(resolve => {
        this.previewBIRForm2307({
          company: {
            name: process.env?.VUE_APP_BIR_FORM_COMPANY_NAME || '',
            address: process.env?.VUE_APP_BIR_FORM_COMPANY_ADDRESS || '',
            zip_code: process.env?.VUE_APP_BIR_FORM_COMPANY_ZIP_CODE || '',
            tin: process.env?.VUE_APP_BIR_FORM_COMPANY_TIN || ''
          },
          payee: {
            name: paymentRequest.voucher?.payee || '',
            address: paymentRequest.business_partner?.address || '',
            foreign_address: '',
            zip_code: '',
            tin: paymentRequest?.bp_tin || ''
          },
          requester: {
            name: process.env?.VUE_APP_BIR_FORM_PAYOR_NAME || '',
            tin: process.env?.VUE_APP_BIR_FORM_PAYOR_TIN || '000-000-000-00000',
            position: process.env?.VUE_APP_BIR_FORM_PAYOR_POSITION || ''
          },
          incomePayments: [
            {
              tax_code: paymentRequest.tax_code?.code || '',
              tax_description: paymentRequest?.tax_description || '',
              quarter: paymentRequest?.report_period || '',
              date: paymentRequest?.report_year || paymentRequest.created_at,
              amount_due: paymentRequest?.taxable_amount || '0.00',
              tax_withheld: paymentRequest?.withholding_tax || ''
            }
          ],
          moneyPayments: []
        }).then(
          onProcessBIRForm => {
            onProcessBIRForm.saveAsBase64({ dataUri: true }).then(
              pdfBIR => {
                this.pdfDatas.birForm = pdfBIR
                resolve(pdfBIR)
              }
            )
          }
        ).catch(errorMessage => {
          this.swalInvalid(`<h6>${errorMessage}, please try again or contact IFAS Support Team.</h6>`)
        })
      })
    },

    async printAttachments (paymentRequest) {
      this.state.pdfRendering = true
      return new Promise(resolve => {
        this.pdfLibCopyAttachmentsTwo(paymentRequest).then(
          convertedPDFToPNG => {
            this.pdfDatas.attachments = convertedPDFToPNG
            resolve(convertedPDFToPNG)
          }
        )
      }).finally(() => {
        this.state.pdfRendering = false
      })
    },

    printAllForms (pdfRFP, pdfBIR, pdfCheckVoucher, paymentRequest) {
      return new Promise(resolve => {
        this.pdfLib({
          title: 'Payment Request',
          author: 'Integrated Finance Accounting System',
          creator: 'Integrated Finance Accounting System',
          subject: 'Payment Request'
        }).then(
          async pdfDocument => {
            await this.pdfLibCopyPages(pdfDocument, pdfRFP, [0])
            await this.pdfLibCopyPages(pdfDocument, pdfBIR, [0])
            await this.pdfLibCopyPages(pdfDocument, pdfCheckVoucher, [0])
            if (paymentRequest.attachments.length <= 0) {
              return pdfDocument
            }
            return await this.pdfLibCopyAttachments(pdfDocument, paymentRequest)
          }
        ).then(
          onProcessPrintAll => {
            onProcessPrintAll.saveAsBase64({ dataUri: true }).then(
              allForms => {
                this.pdfDatas.allForms = allForms
                resolve(allForms)
              }
            )
          }
        )
      })
    },

    onPrintPaymentRequest (event) {
      event.preventDefault()
      this.$refs.formPaymentRequest.validate().then(
        allFieldsAreValid => {
          if (allFieldsAreValid) {
            this.swalConfirmWithRemarks({
              html: `<h6>${event.target.innerText}?</h6>`,
              confirmButtonText: event.target.innerText,
              remarksRequired: false,
              preConfirm: remarks => {
                this.state.busy = true
                return this.onPutPaymentRequest({
                  id: this.paymentRequest.id,
                  status: 'Printed',
                  remarks: remarks
                })
              }
            })
          } else {
            this.swalInvalid()
          }
        }
      )
    },

    async onPutPaymentRequest (payload) {
      return await this.$refs.prPending.onPut(payload).then(
        ({ message }) => {
          this.$refs.prPending.tablePendingRefresh()
          this.swalSuccess(message).then(() => {
            this.$bvModal.hide('modal-payment-request')
          })
        }
      ).catch(errors => {
        this.$refs.formPaymentRequest.setErrors(errors)
      }).finally(() => {
        this.state.busy = false
      })
    }

  }

}
</script>
<style>
@media print {

  .printing-height-one {
    height: calc(100vh / 1.05)
  }

  .printing-height {
    height: calc(100vh / 2.15)
  }

  .pageBreakAfter {
    page-break-after: always !important;
  }
}
</style>
